import { useEffect } from 'react';
import './App.css';
import { useAddToHomescreenPrompt } from './components/AddToHomeScreen';
import Routes from './routes';

function App() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();


  
  return <Routes />;
}

export default App;

