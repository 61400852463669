import React, { useEffect, useState } from "react";
import "./Style.css";
import MyLogo from "../Images/myLogo.png";
import FilledButton from "../components/FilledButton";
import FiEye from "../Images/fi_eye.png";
import FIBack from "../Images/fi_back.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../services";
import ALertRed from "../Images/alertred.png";
import ClosedEye from "../Images/closedEye.png";
import Loader from "react-js-loader";

function Login() {
  const navigate = useNavigate();

  const [myEmail, setMyEmail] = useState("");
  const [myPassword, setMyPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const getUserData = () => {
    const user = localStorage.getItem("user");
    // console.log('user',user)
    try {
      return JSON.parse(user);
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const user = getUserData();
    if (user != null) {
      navigate("/homepage");
    }
    // console.log('my user is ',user);
  }, []);

  const handleApiError = (error) => {
    // If the error is a response error
    if (error) {
      const errorDetails = Object.entries(error)
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n");
      // alert(`Login Error:\n${errorDetails}`);
    } else {
      // For other errors
      // alert(`Error: ${error.message}`);
    }
  };

  const LogToAccount = () => {
    setIsLoader(true);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(myEmail)) {
      console.log("hello oussama email invalid");
      setErrors({ email: "Email invalide" });
      setIsLoader(false);
      return;
    } else if (myPassword.length < 2) {
      console.log("hello oussama email invalid");
      setErrors({ password: "Email invalide" });
      setIsLoader(false);
      return;
    }

    const data = {
      email: myEmail,
      password: myPassword,
    };

    axios
      .post(`${API_URL}/login`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setIsLoader(false);
        console.log(response);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        navigate("/homepage");
      })
      .catch(function (error) {
        setIsLoader(false);
        console.log(error.response.data.data);
        // alert(`Login Error: Email address or password is incorrect`);
        setErrors({
          emailorpassword: "Email address or password is incorrect.",
        });
        // handleApiError(error.response.data.data)
      });
  };

  return (
    <div className="mySplash">
      {/* <div className="mySplashBox"> */}
      <div className="topNavBox loginNav">
        <img
          src={FIBack}
          width={24}
          height={24}
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
      <div className="myLogoContianer">
        <img className="myLogoImg" alt="airfrancelogo" src={MyLogo} />
      </div>
      <div>
      <div><h2 className="myLoginTitle">We’re happy to see you again!</h2></div>

        {isLoader && (
          <div className="overlayLoader">
            <Loader
              type="box-rotate-x"
              bgColor={"#091C62"}
              color={"#091C62"}
              // title={"box-rotate-x"}
              size={100}
            />
          </div>
        )}
        <div className="inputBox">
          <h4 className="inputTitle">Professional e-mail address*</h4>
          <input
            className="myEmailInput"
            type="email"
            id="name"
            placeholder="adresse@mail.fr"
            name="email"
            required
            onChange={(e) => {
              setMyEmail(e.target.value);
              setErrors({});
            }}
          />
          {errors.email && (
            <div>
              <span className="input-hint red-text">
                <img
                  className="pauseInfo"
                  src={ALertRed}
                  width={20}
                  height={20}
                />
                Email is invalid. Please enter a valid email address.
              </span>
            </div>
          )}
          {errors.emailorpassword && (
            <div>
              <span className="input-hint red-text">
                <img
                  className="pauseInfo"
                  src={ALertRed}
                  width={20}
                  height={20}
                />
                Email address or password is incorrect.
              </span>
            </div>
          )}
        </div>
        <div className="inputBox">
          <h4 className="inputTitle">Password*</h4>
          <div className="myEyeBox">
            <img
              className="myEyePassword"
              src={isPasswordShow ? ClosedEye : FiEye}
              width={20}
              height={20}
              onClick={() => {
                setIsPasswordShow((prev) => !prev);
              }}
            />
            <input
              className="myEmailInput"
              type={isPasswordShow ? "text" : "password"}
              id="password"
              placeholder="password"
              name="password"
              required
              onChange={(e) => {
                setMyPassword(e.target.value);
                setErrors({});
              }}
            />
            {errors.password && (
              <div>
                <span className="input-hint red-text">
                  <img
                    className="pauseInfo"
                    src={ALertRed}
                    width={20}
                    height={20}
                  />
                  Password is invalid. Please enter a valid password.
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="forgetPsLinkBox">
          <a
            rel="#"
            className="forgetPsLink"
            onClick={() => {
              navigate("/forgetPassword");
            }}
          >
            Forgot password?
          </a>
        </div>
      </div>

      <div className="ctaLoginBox">
        <FilledButton
          text={"Login"}
          onClick={() => {
            LogToAccount();
            // navigate("/homepage");
          }}
        />
      </div>

      <div className="bottomCtaBox">
        <span className="bottomCtaTxt">Don't have an account yet?</span>
        <a
          className="forgetPsLink"
          onClick={() => {
            navigate("/firstsignUp");
          }}
        >
          Sign up
        </a>
      </div>
      {/* </div> */}
    </div>
  );
}

export default Login;
