import React, { useState } from "react";
import FiX from "../../Images/fi_x.png";
import QuizImage from "../../Images/quizimg.png";

import TrueImage from "../../Images/true.png";
import FalseImage from "../../Images/false.png";

import "../Style.css";
import FilledButton from "../../components/FilledButton";
import { API_URL } from "../../services";
import axios from "axios";

function QuizQuestion({
  thisQuestion,
  quizzQuestions,
  myStep,
  setMyStep,
  questionNbr,
  onOpenModal,
  onCloseModal,
  thisQuizzScore,
  setThisQuizzScore,
  howMuchCorrect,
  setHowMuchCorrect,
  myCampaign
}) {
  // console.log("thisQuestion =>", thisQuestion);
  const [selected, setSelected] = useState(-1);
  const [trueOrNot, setTrueOrNot] = useState(-1);
  const myUser = JSON.parse(localStorage.getItem("user"));

  const submitProposal = (idSelected) => {
    console.log("selected", selected, myUser);
    const data = {
      proposition_id: selected.id,
    };

    axios
      .post(`${API_URL}/submit_proposal`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + myUser.token,
        },
      })
      .then(function (response) {
        console.log("i sent the proposal successfuly");
      })
      .catch(function (error) {
        console.log("i didnot sent the proposal Its Failed", error);
      });
  };

  const checkAnswer = (index) => {
    submitProposal();
    if (selected.is_answer) {
      console.log("correct answer");
      setTrueOrNot(true);
      setThisQuizzScore(thisQuizzScore + thisQuestion?.points);
      setHowMuchCorrect(howMuchCorrect+1)
    } else {
      console.log("wrong answer");
      setTrueOrNot(false);
    }
  };

  const goNext = () => {
    if (myStep == 0) {
      setMyStep(1);
    } else {
      setMyStep(0);
    }
  };

  return (
    <div className="mySplash">
      <div>
        <div className="forgetTopNavBox">
          <span></span>
          <span className="myForgetTitle myQuizTitle">{myCampaign?.name}</span>
          <img
            src={FiX}
            width={24}
            height={24}
            onClick={() => {
              onOpenModal();
              // console.log('login');
              // navigate("/login");
            }}
          />
        </div>
      </div>
      <div>
        <div className="quizBox mw700">
          <span className="questionNbr">
            Question {`${questionNbr + 1}/${quizzQuestions.length}`}
          </span>
          <img
            src={thisQuestion?.pic_url != null ?`https://af.lebackyard.ovh/storage/${thisQuestion?.pic_url}` : QuizImage}
            width={"100%"}
            height={150}
            onClick={() => {
              // navigate("/login");
            }}
          />
          <p>
            {/* In 2023, the Air France-KLM Group was the world's leading Sustainable
          Aviation Fuel (SAF) user for the second consecutive year? */}
            {thisQuestion?.wording}
          </p>
        </div>
      </div>

      <div className="">
        <div className="quizOptions ">
          <div className="optionCorner">
            {thisQuestion?.propositions.map((element, index) => {
              return (
                <div
                  key={index}
                  className={`optionBox 
                ${
                  trueOrNot !== -1 &&
                  !element.is_answer &&
                  selected.id === element.id
                    ? "will-disabled"
                    : ""
                }
                ${trueOrNot !== false && element.is_answer ? "is-selected" : ""}
                ${
                  trueOrNot == false && selected.id === element.id
                    ? "is-selected"
                    : ""
                } 
                
                ${trueOrNot != -1 ? "checked" : ""} 
                ${
                  trueOrNot != -1 && element.is_answer
                    ? "bgActive my-correct"
                    : ""
                } ${
                    selected.id === element.id && trueOrNot == false
                      ? "bgActive my-false"
                      : ""
                  }`}
                  onClick={() => {
                    if (trueOrNot == -1) {
                      setSelected(element);
                    }
                  }}
                >
                  {(selected.id === element.id && trueOrNot != -1) ||
                  (trueOrNot != -1 && element.is_answer) ? (
                    <img
                      className="mytrueimg"
                      src={element.is_answer ? TrueImage : FalseImage}
                      width={26}
                      height={26}
                      onClick={() => {
                        // navigate("/login");
                      }}
                    />
                  ) : null}

                  <div
                    className={`cercle ${
                      trueOrNot != -1 ? "checked-circle " : ""
                    }`}
                  >
                    {selected.id === element.id && (
                      <div
                        className={`cercle-inside ${
                          trueOrNot != -1 ? "checked-circle-inside" : ""
                        }`}
                      ></div>
                    )}
                  </div>
                  <span
                    className={`optionTxt ${
                      trueOrNot !== -1 &&
                      !element.is_answer &&
                      selected !== index
                        ? "will-disabled will-disabled-border "
                        : ""
                    } ${
                      selected.id === element.id && trueOrNot == -1
                        ? "is-selected"
                        : ""
                    }`}
                  >
                    {element.wording}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="ctaLoginBox">
        <FilledButton
          text={trueOrNot == -1 ? "Check" : "Continue"}
          onClick={() => {
            trueOrNot == -1 ? checkAnswer() : goNext();
            console.log("go go upp --");
          }}
          disabled={selected === -1}
        />
      </div>
    </div>
  );
}

export default QuizQuestion;

/*

  we pressed check : 

  choice selected and true

  choice selected and false  

  choice selected and false

  choice selected and false

  choice selected and false

*/
