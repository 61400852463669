export const languages = [
  //    English
  {
    splash: {
      welcomeTxt:
        "Welcome to the app that will test your knowledge of the Air France-KLM Group. Want to give it a try? Log in and play!",
      create: "Create account",
      login: "Login",
    },
    login: {
      title: "We’re happy to see you again!",
      addTxt: "Professional e-mail address*",
      passTxt: "Password*",
      fgTxt: "Forgot password?",
      loginTxt: "Login",
      dontTxt: "Don't have an account yet?",
      signupTxt: "Sign up",
    },
    signup: {
      title: "Let’s get you started!",
      addTxt: "Professional e-mail address*",
      domainPlaceHolder: "Select an option",
      userNameTxt: "Username*",
      userNamePlaceHolder: "username",
      passTxt: "Password*",
      signupBtn: "Continue",
      alreadyTxt: "Already have an account?",
      login: "Login",
    },
    signup2: {
      title: "Add professional information.",
      firstName: "First name*",
      lastName: "Last name*",
      whatCompanyTxt: "What company are you working for?*",
      whatCompanyPlaceholder: "Select a company from the list",
      whatDepartementTxt: "What is your department?*",
      whatDepartementPlaceholder: "Select a department from the list",
      whatCountryTxt: "Where are you located?*",
      whatCountryPlaceholder: "Select a country from the list",
      hintTxt:
        "By creating an account, I agree to the Terms and conditions and Privacy Policy",
      btnTxt: "Create account",
    },
    home: {
      title: "Welcome aboard!",
      longTxt:
        "For its 20th anniversary, the Air France-KLM Group is giving you the chance to play, to find out more about the Group and to win prizes. Several quizzes will be offered throughout the year. Let's start!",
      campaign: "Campaign",
      longTxt2:
        "Show your general knowledge of the Air France-KLM Group! Every week for the next 3 weeks, a series of questions will be asked. Answer them all to earn a maximum of points. Good luck to you!",
      discoverBtn: "Discover the campaign",
      goRank: "Go to ranking",
    },
    modifProfil: {
      title: "My profile",
      usernameTitle: "UsernameTxt",
      firstNameTxt: "First name",
      lastName: "Last name",
      whatCompanyTxt: "What company are you working for?",
      whatDepartementTxt: "What is your department?",
      whatCountryTxt: "Where are you located?",
      addTxt: "Professional e-mail address*",
      passTxt: "Password*",
      changePassword: "Change my password",
      logout: "Log out",
      saveChanges: "Save changes",
    },
    changePassword: {
      changeTitle: "Change my password",
      currentPassTitle: "Current password*",
      currentPassPlace: "current password",
      newPassTitle: "New password*",
      newPassPlace: "new password",
      confirmTitle: "Confirm the new password*",
      confirmTxtPlace: "confirm the password",
      setBtn: "Set new password",
      cancel: "Cancel",
    },
    rankPage: {
      ranking: "Top Gamers",
      points: "points",
    },
    quizListPage: {
      session: "Session",
      completeBtn: "Complete the quiz session",
      question: "Question",
      checkBtn: "Check",
      continue: "Continue",
      next: "Next question",
      submit: "Submit",
      resultTitle: "My result",
      correct: "correct answers",
      youHaveEarned: "You have earned",
      points: "points",
      goToRank: "Go to ranking",
      backHome: "Back to home",
    },
    popups: {
      dontLeave: "Don't leave yet!",
      uhaveNoValidated:
        "You have not validated your answer to the question. Your participation will not be taken into account.",
      continue: "Continue the session",
      back: "Back to home",
    },
  },

  //   French
  {
    splash: {
        welcomeTxt:
          "Bienvenue sur l'application qui testera vos connaissances sur le groupe Air France-KLM. Vous voulez essayer? Connectez-vous et jouez!",
        create: "Créer un compte",
        login: "Connexion",
      },
    login: {
      title: "Nous sommes heureux de vous revoir !",
      addTxt: "Adresse e-mail professionnelle*",
      passTxt: "Mot de passe*",
      fgTxt: "Mot de passe oublié ?",
      loginTxt: "Connexion",
      dontTxt: "Vous n'avez pas encore de compte ?",
      signupTxt: "S'inscrire",
    },
    signup: {
      title: "Commençons !",
      addTxt: "Adresse e-mail professionnelle*",
      domainPlaceHolder: "Sélectionnez une option",
      userNameTxt: "Nom d'utilisateur*",
      userNamePlaceHolder: "nom d'utilisateur",
      passTxt: "Mot de passe*",
      signupBtn: "Continuer",
      alreadyTxt: "Vous avez déjà un compte ?",
      login: "Connexion",
    },
    signup2: {
      title: "Ajoutez des informations professionnelles.",
      firstName: "Prénom*",
      lastName: "Nom*",
      whatCompanyTxt: "Pour quelle entreprise travaillez-vous ?*",
      whatCompanyPlaceholder: "Sélectionnez une entreprise dans la liste",
      whatDepartementTxt: "Quel est votre département ?*",
      whatDepartementPlaceholder: "Sélectionnez un département dans la liste",
      whatCountryTxt: "Où êtes-vous situé ?*",
      whatCountryPlaceholder: "Sélectionnez un pays dans la liste",
      hintTxt:
        "En créant un compte, j'accepte les Conditions générales et la Politique de confidentialité",
      btnTxt: "Créer un compte",
    },
    home: {
      title: "Bienvenue à bord !",
      longTxt:
        "Pour son 20e anniversaire, le groupe Air France-KLM vous offre la chance de jouer, d'en apprendre davantage sur le groupe et de gagner des prix. Plusieurs quiz seront proposés tout au long de l'année. Commençons !",
      campaign: "Campagne",
      longTxt2:
        "Montrez vos connaissances générales sur le groupe Air France-KLM ! Chaque semaine pendant les 3 prochaines semaines, une série de questions sera posée. Répondez à toutes pour gagner un maximum de points. Bonne chance !",
      discoverBtn: "Découvrir la campagne",
      goRank: "Voir le classement",
    },
    modifProfil: {
      title: "Mon profil",
      usernameTitle: "Nom d'utilisateur",
      firstNameTxt: "Prénom",
      lastName: "Nom",
      whatCompanyTxt: "Pour quelle entreprise travaillez-vous ?",
      whatDepartementTxt: "Quel est votre département ?",
      whatCountryTxt: "Où êtes-vous situé ?",
      addTxt: "Adresse e-mail professionnelle*",
      passTxt: "Mot de passe*",
      changePassword: "Changer mon mot de passe",
      logout: "Se déconnecter",
      saveChanges: "Enregistrer les modifications",
    },
    changePassword: {
      changeTitle: "Changer mon mot de passe",
      currentPassTitle: "Mot de passe actuel*",
      currentPassPlace: "mot de passe actuel",
      newPassTitle: "Nouveau mot de passe*",
      newPassPlace: "nouveau mot de passe",
      confirmTitle: "Confirmer le nouveau mot de passe*",
      confirmTxtPlace: "confirmer le mot de passe",
      setBtn: "Définir le nouveau mot de passe",
      cancel: "Annuler",
    },
    rankPage: {
      ranking: "Top Gamers",
      points: "points",
    },
    quizListPage: {
      session: "Session",
      completeBtn: "Terminer la session de quiz",
      question: "Question",
      checkBtn: "Vérifier",
      continue: "Continuer",
      next: "Question suivante",
      submit: "Soumettre",
      resultTitle: "Mon résultat",
      correct: "réponses correctes",
      youHaveEarned: "Vous avez gagné",
      points: "points",
      goToRank: "Voir le classement",
      backHome: "Retour à l'accueil",
    },
    popups: {
      dontLeave: "Ne partez pas encore !",
      uhaveNoValidated:
        "Vous n'avez pas validé votre réponse à la question. Votre participation ne sera pas prise en compte.",
      continue: "Continuer la session",
      back: "Retour à l'accueil",
    },
  },

  //   Dutch (Nederlands)
  {
    splash: {
        welcomeTxt:
          "Welkom bij de app die je kennis over de Air France-KLM Groep zal testen. Wil je het proberen? Log in en speel!",
        create: "Account aanmaken",
        login: "Inloggen",
      },
    login: {
      title: "We zijn blij je weer te zien!",
      addTxt: "Professioneel e-mailadres*",
      passTxt: "Wachtwoord*",
      fgTxt: "Wachtwoord vergeten?",
      loginTxt: "Inloggen",
      dontTxt: "Heb je nog geen account?",
      signupTxt: "Aanmelden",
    },
    signup: {
      title: "Laten we beginnen!",
      addTxt: "Professioneel e-mailadres*",
      domainPlaceHolder: "Selecteer een optie",
      userNameTxt: "Gebruikersnaam*",
      userNamePlaceHolder: "gebruikersnaam",
      passTxt: "Wachtwoord*",
      signupBtn: "Doorgaan",
      alreadyTxt: "Heb je al een account?",
      login: "Inloggen",
    },
    signup2: {
      title: "Voeg professionele informatie toe.",
      firstName: "Voornaam*",
      lastName: "Achternaam*",
      whatCompanyTxt: "Voor welk bedrijf werk je?*",
      whatCompanyPlaceholder: "Selecteer een bedrijf uit de lijst",
      whatDepartementTxt: "Wat is je afdeling?*",
      whatDepartementPlaceholder: "Selecteer een afdeling uit de lijst",
      whatCountryTxt: "Waar ben je gevestigd?*",
      whatCountryPlaceholder: "Selecteer een land uit de lijst",
      hintTxt:
        "Door een account aan te maken, ga ik akkoord met de Algemene voorwaarden en het Privacybeleid",
      btnTxt: "Account aanmaken",
    },
    home: {
      title: "Welkom aan boord!",
      longTxt:
        "Voor zijn 20-jarig jubileum biedt de Air France-KLM Groep je de kans om te spelen, meer te weten te komen over de Groep en prijzen te winnen. Gedurende het jaar zullen verschillende quizzen worden aangeboden. Laten we beginnen!",
      campaign: "Campagne",
      longTxt2:
        "Toon je algemene kennis over de Air France-KLM Groep! Elke week gedurende de komende 3 weken zal een reeks vragen worden gesteld. Beantwoord ze allemaal om een ​​maximum aantal punten te verdienen. Veel succes!",
      discoverBtn: "Ontdek de campagne",
      goRank: "Ga naar ranglijst",
    },
    modifProfil: {
      title: "Mijn profiel",
      usernameTitle: "Gebruikersnaam",
      firstNameTxt: "Voornaam",
      lastName: "Achternaam",
      whatCompanyTxt: "Voor welk bedrijf werk je?",
      whatDepartementTxt: "Wat is je afdeling?",
      whatCountryTxt: "Waar ben je gevestigd?",
      addTxt: "Professioneel e-mailadres*",
      passTxt: "Wachtwoord*",
      changePassword: "Wijzig mijn wachtwoord",
      logout: "Uitloggen",
      saveChanges: "Wijzigingen opslaan",
    },
    changePassword: {
      changeTitle: "Wijzig mijn wachtwoord",
      currentPassTitle: "Huidig wachtwoord*",
      currentPassPlace: "huidig wachtwoord",
      newPassTitle: "Nieuw wachtwoord*",
      newPassPlace: "nieuw wachtwoord",
      confirmTitle: "Bevestig het nieuwe wachtwoord*",
      confirmTxtPlace: "bevestig het wachtwoord",
      setBtn: "Nieuw wachtwoord instellen",
      cancel: "Annuleren",
    },
    rankPage: {
      ranking: "Top Gamers",
      points: "punten",
    },
    quizListPage: {
      session: "Sessie",
      completeBtn: "Voltooi de quizsessie",
      question: "Vraag",
      checkBtn: "Controleren",
      continue: "Doorgaan",
      next: "Volgende vraag",
      submit: "Indienen",
      resultTitle: "Mijn resultaat",
      correct: "goede antwoorden",
      youHaveEarned: "Je hebt verdiend",
      points: "punten",
      goToRank: "Ga naar ranglijst",
      backHome: "Terug naar home",
    },
    popups: {
      dontLeave: "Ga nog niet weg!",
      uhaveNoValidated:
        "Je hebt je antwoord op de vraag niet bevestigd. Je deelname wordt niet meegerekend.",
      continue: "Doorgaan met de sessie",
      back: "Terug naar home",
    },
  },
];
