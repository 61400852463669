import React from "react";
import FIBack from "../Images/fi_back.png";
import FilledButton from "./FilledButton";
import FiX from "../Images/fi_x.png";
import resetPsImg from "../Images/resetPs.png";
import { useNavigate } from "react-router-dom";

function PopUpLeaveQuiz({onCloseModal}) {
  const navigate = useNavigate();
  return (
    <div className="myContainer mxw700auto">
      {/* <div className="topNavRight">
        <img src={FiX} width={24} height={24} />
      </div> */}
      {/* 
      <div className="myCenterDiv mgBtm20">
        <img src={resetPsImg} width={60} height={60} />
      </div> */}
      <div className="myCenterDiv">
        <h2>Don't leave yet!</h2>
        <p>
          You have not validated your answer to the question. Your participation
          will not be taken into account.
        </p>
      </div>

      <div className="ctaLoginBox">
        <FilledButton
          text={"Continue the session"}
          onClick={() => {
            // navigate("/login");
            onCloseModal()
          }}
        />
        <div className="mt20">
          <a
            rel="#"
            className="forgetPsLink"
            onClick={() => {
              navigate("/homepage");
            }}
          >
            Back to home
          </a>
        </div>
      </div>
    </div>
  );
}

export default PopUpLeaveQuiz;
