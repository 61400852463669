import React, { useState } from "react";
import FiX from "../../Images/fi_x.png";
import QuizImage from "../../Images/quizimg.png";

import "../Style.css";
import FilledButton from "../../components/FilledButton";
import { useNavigate } from "react-router-dom";

function QuestionExplain({
  thisQuestion,
  quizzQuestions,
  myStep,
  setMyStep,
  setQuestionNbr,
  questionNbr,
  onOpenModal,
  onCloseModal,
  myCampaign
}) {
  const navigate = useNavigate();

  const goSubmit = () => {
    if (questionNbr + 1 < quizzQuestions.length) {
      setQuestionNbr(questionNbr + 1);
      // setQuestionNbr(+1);
      setMyStep(0);
    } else {
      // navigate("/quiz-result");
      setMyStep(2);
    }
  };

  return (
    <div className="mySplash my-flex">
      <div className="my-splash-box">
        <div className="forgetTopNavBox">
          <span></span>
          <span className="myForgetTitle myQuizTitle">{myCampaign?.name}</span>
          <img
            src={FiX}
            width={24}
            height={24}
            onClick={() => {
              onOpenModal()
              // navigate(-1);
              // navigate("/login");
            }}
          />
        </div>
        <div className="quizBox">
          <span className="questionNbr">
            Question {`${questionNbr + 1}/${quizzQuestions.length}`}
          </span>
          <p>{thisQuestion?.answer}</p>
        </div>
      </div>
      <div className="ctaLoginBox">
        <FilledButton
          text={(questionNbr + 1 < quizzQuestions.length) ? "Next question" : "Submit"}
          onClick={() => {
            console.log("go go upp --");
            // checkAnswer();
            goSubmit();
          }}
        />
      </div>
    </div>
  );
}

export default QuestionExplain;
