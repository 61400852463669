import React from "react";
import "./Style.css";
import MyLogo from "../Images/myLogo.png";
import FilledButton from "../components/FilledButton";
import OutlineButton from "../components/OutlineButton";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useAddToHomescreenPrompt } from "../components/AddToHomeScreen";
import { languages } from "../Languages";

function Splach() {
  const navigate = useNavigate();
  const langOptions = ["EN", "FR", "NL"];

  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  return (
    <div className="mySplash">
      <div className="mySplashBox">
        {/* <button onClick={promptToInstall}>Add to Home Screen</button> */}
        <div className="forgetTopNavBox">
          {/* <img src={FIBack} width={24} height={24} /> */}
          <span className="rankTitle"></span>
          <span className="rankTitle"></span>
          <div className="dFlex">
            <Dropdown
              className="langDrop"
              placeholderClassName="langDropPlaceHolder"
              menuClassName={"langDropMenu"}
              options={langOptions}
              onChange={() => {}}
              // value={defaultOption}
              placeholder={langOptions[0]}
            />
          </div>
        </div>
        <div className="myLogoContianer">
          <img className="myLogoImg" alt="airfrancelogo" src={MyLogo} />
        </div>
        <div className="mySplashTextContaner">
          <h3 className="splashText">
            {languages[2].splash.welcomeTxt}
          </h3>
        </div>

        <div className="mySplashCTaContianer">
          <FilledButton
            text={"Create account"}
            onClick={() => {
              console.log("We will create an account");
              navigate("/firstsignUp");
            }}
          />
          <OutlineButton
            text={"Login"}
            onClick={() => {
              console.log("We will login");
              navigate("/login");
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Splach;
