import React, { useEffect, useState } from "react";
import FIBack from "../../Images/fi_back.png";

import RankGold from "../../Images/gold.png";
import RankSilver from "../../Images/silver.png";
import RankBronze from "../../Images/bronze.png";
import GoUp from "../../Images/goUp.png";

import { useNavigate } from "react-router-dom";

import "./Style.css";
import axios from "axios";
import { API_URL } from "../../services";
function RankingPage() {
  const navigate = useNavigate();
  const myUser = JSON.parse(localStorage.getItem('user'))
  const [myList, setMyList] = useState([])
  const myRankList = ["1", "2", "3", "4", "5", "6", "7","8", "9", "10", "11", "12", "13", "14","15", "16", "17", "18", "19", "20", "21"];
  const userRank = 5;
  
  const getRankingList = () => {
    const headers = {
      Authorization:
        "Bearer " +
        myUser.token,
    };
    const newAr = [];
    axios
      .get(`${API_URL}/top_gamers`, {
        headers
      })
      .then(function (response) {
        console.log('My List response =>',response.data.data);

        // (response.data.data).map((item,index)=> {
        //   newAr.push({
        //     label:item.name,
        //     value:item.id
        //   })
        // });
        setMyList(response.data.data);
      })
      .catch(function (error) {
        console.log(error.response.data);
        // alert(`Login Error: Email address or password is incorrect`);
      });
  };

  useEffect(() => {
    console.log('dffdddf')
    getRankingList()
  }, [])
  

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div className="myRankContainer">
      <div className="forgetTopNavBox">
        <img
          src={FIBack}
          width={24}
          height={24}
          onClick={() => {
            navigate('/homepage');
          }}
        />
        <span className="rankTitle">Top Gamers</span>
        <span></span>
      </div>

      {myList.map((item, index) => {
        return (
          <div className={`dFlex rankRow  ${item.id ===myUser.id && 'myRank' }` }key={index}>
            <div>
              <span>{index+1}e</span> <span>|</span> <span>{item.name}</span>
            </div>
            <div className="dFlex centered">
              {index === 0 ? (
                <img
                  className="rankImg"
                  src={RankGold}
                  width={18}
                  height={30}
                />
              ) : index === 1 ? (
                <img
                  className="rankImg"
                  src={RankSilver}
                  width={18}
                  height={30}
                />
              ) : index === 2 ? (
                <img
                  className="rankImg"
                  src={RankBronze}
                  width={18}
                  height={30}
                />
              ) : null}

              <span>{item.total_points} points</span>
            </div>
          </div>
        );
      })}
      <div
        className="goUpBox"
        onClick={() => {
          console.log("fdfdfdf");
          scrollToTop()
        }}
      >
        <img src={GoUp} width={40} height={40} />
      </div>
    </div>
  );
}

export default RankingPage;
