import React, { useState } from "react";
import "./Style.css";
import MyLogo from "../Images/myLogo.png";
import FilledButton from "../components/FilledButton";
import FiEye from "../Images/fi_eye.png";
import ClosedEye from "../Images/closedEye.png";
import PauseInfo from "../Images/fipause.png";
import ALertRed from "../Images/alertred.png";
import resetPsImg from "../Images/resetPs.png";
import bottomLogo from "../Images/bottomLogo.png";
import Loader from "react-js-loader";

import FIBack from "../Images/fi_back.png";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { object, string, number, date, InferType } from "yup";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../services";

function FirstSignUp() {
  const navigate = useNavigate();

  const schema = object().shape({
    username: string().required(),
    // password: string().required().minLength(8),
    email: string().email(),
  });

  const options = ["office.fr", "mydev.fr", "global.fr"];

  const myDomains = [
    {
      label: "td.klm.com",
      value: 0,
    },
    {
      label: "airfrance.fr",
      value: 1,
    },
    {
      label: "airfranceklm.com",
      value: 2,
    },
    {
      label: "klm.com",
      value: 3,
    },
    {
      label: "transavia.com",
      value: 4,
    },
    {
      label: "fr.transavia.com",
      value: 5,
    },
    {
      label: "klmcargo.com",
      value: 6,
    },
    {
      label: "martinair.com",
      value: 7,
    },
    {
      label: "hop.fr",
      value: 8,
    },
  ];
  const defaultOption = options[0];

  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [myPassword, setMyPassword] = useState("");
  const [myEmail, setMyEmail] = useState("");
  const [myEmailDomain, setMyEmailDomain] = useState(defaultOption);
  const [myUserName, setMyUserName] = useState("");
  const [errors, setErrors] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("hello there handleSubmit");
    const formData = {
      username: e.target.username.value,
      email: e.target.email.value,
      password: e.target.password.value,
    };
    const errors = schema.validate(formData);
    if (errors.length > 0) {
      // There are errors in the form data
      alert(errors.join("\n"));
    } else {
      // The form data is valid, do something with it
    }
  };

  const firstVerification = () => {
    setIsLoader(true);
    const data = {
      name: myUserName,
      email: `${myEmail}@${myEmailDomain}`,
      password: myPassword,
    };

    axios
      .post(`${API_URL}/verify_registration_fist_step`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response);
        setIsLoader(false);
        navigate("/secondsignUp", {
          state: {
            email: myEmail,
            emailDomaine: myEmailDomain,
            username: myUserName,
            password: myPassword,
          },
        });
        // navigate("/homepage");
      })
      .catch(function (error) {
        setIsLoader(false);
        console.log(error.response.data.data);
        setErrors(error.response.data.data);
        // alert(`Login Error: Email address or password is incorrect`);
        // handleApiError(error.response.data.data);
      });
  };

  function validatePassword(password) {
    // Check length
    if (password.length <= 8) {
      return false;
      // return "Password needs more than 8 characters.";
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
      // return "Password needs at least one uppercase letter.";
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return false;
      // return "Password needs at least one lowercase letter.";
    }

    // Check for at least one number or special character
    if (!/[0-9!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return false;
      // return "Password must contain a number or special character.";
    }

    return true;
    // If all conditions are metﬂ
  }

  const handleChange = (event) => {
    console.log("event now is ", event.target);
    setMyEmailDomain(event);
  };



  return (
    <div className="mySplash">
      <div className="mySplashBoxFs">
        <div className="topNavBox navWithSteps">
          <img
            onClick={() => {
              navigate(-1);
            }}
            src={FIBack}
            width={24}
            height={24}
          />
          <div className="myStepIndicatorBox">
            <div className="myStepIndicator firstStep"></div>
            <div className="myStepIndicator secondStep"></div>
          </div>
          <div></div>
        </div>

        <div>
          {isLoader && (
          <div className="overlayLoader">
          <Loader
            type="box-rotate-x"
            bgColor={"#091C62"}
            color={"#091C62"}
            // title={"box-rotate-x"}
            size={100}
          />
        </div>
          )}

          <h2 className="inputTitle maxW700">Let’s get you started!</h2>
          <form onSubmit={handleSubmit}>
            <div className="inputBox">
              <h4 className="inputTitle">Professional e-mail address*</h4>
              <div className="myInputsBox">
                <input
                  className="myEmailInput inputDemiWidth"
                  type="email"
                  id="name"
                  placeholder="lorem.ipsum"
                  name="email"
                  required
                  onChange={(e) => setMyEmail(e.target.value)}
                />

                <Dropdown
                  className="myDropDown"
                  placeholderClassName="myPlaceHolder"
                  menuClassName={"menuClassName"}
                  options={myDomains}
                  onChange={(e) => {
                    setMyEmailDomain(e.label);
                    // console.log(e.value);
                  }}
                  // value={defaultOption}
                  placeholder="Select an option"
                />
              </div>
              {errors.email && (
                <div>
                  <span className="input-hint red-text">
                    <img
                      className="pauseInfo"
                      src={ALertRed}
                      width={16}
                      height={16}
                    />
                    This email is already used.
                  </span>
                </div>
              )}
            </div>
            <div className="inputBox">
              <h4 className="inputTitle">Username*</h4>
              <input
                className="myEmailInput"
                type="text"
                id="username"
                placeholder="username"
                name="username"
                required
                onChange={(e) => setMyUserName(e.target.value)}
              />
              {errors.name && (
                <div>
                  <span className="input-hint red-text">
                    <img
                      className="pauseInfo"
                      src={ALertRed}
                      width={16}
                      height={16}
                    />
                    This username is already used.
                  </span>
                </div>
              )}
              {myUserName.length > 0 && (
                <div>
                  <span className="input-hint">
                    <img
                      className="pauseInfo"
                      src={PauseInfo}
                      width={16}
                      height={16}
                    />
                    Usernames will be visible to all participants.
                  </span>
                </div>
              )}
            </div>
            <div className="inputBox">
              <h4 className="inputTitle">Password*</h4>
              <div className="myEyeBox">
                <img
                  className="myEyePassword"
                  src={isPasswordShow ? ClosedEye : FiEye}
                  width={20}
                  height={20}
                  onClick={() => {
                    setIsPasswordShow((prev) => !prev);
                  }}
                />
                <input
                  className="myEmailInput"
                  type={isPasswordShow ? "text" : "password"}
                  onChange={(e) => setMyPassword(e.target.value)}
                  id="password"
                  placeholder="password"
                  name="password"
                  required
                />
              </div>
              {myPassword.length > 0 && (
                <div>
                  <span className="input-hint">
                    <img
                      className="pauseInfo"
                      src={myPassword.length > 8 ? resetPsImg : PauseInfo}
                      width={16}
                      height={16}
                    />
                    You need more than 8 characters
                  </span>
                  <span className="input-hint">
                    <img
                      className="pauseInfo"
                      src={
                        /[A-Z]/.test(myPassword) && /[a-z]/.test(myPassword)
                          ? resetPsImg
                          : PauseInfo
                      }
                      width={16}
                      height={16}
                    />
                    At least 1 uppercase and 1 lowercase letter
                  </span>
                  <span className="input-hint">
                    <img
                      className="pauseInfo"
                      src={
                        /[0-9!@#$%^&*(),.?":{}|<>]/.test(myPassword)
                          ? resetPsImg
                          : PauseInfo
                      }
                      width={16}
                      height={16}
                    />
                    Must contain a number or special character
                  </span>
                </div>
              )}
            </div>
          </form>
        </div>

        <div className="ctaLoginBox">
          <FilledButton
            disabled={
              myEmail.length == 0 ||
              myPassword.length == 0 ||
              myUserName.length == 0 ||
              !validatePassword(myPassword)
            }
            text={"Continue"}
            onClick={() => {
              firstVerification();
            }}
          />
        </div>

        <div className="bottomCtaBox">
          <span className="bottomCtaTxt">Already have an account?</span>
          <a
            className="forgetPsLink"
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </a>
        </div>
      </div>
      <img className="mylogoBtm" src={bottomLogo} width={240} height={240} />
    </div>
  );
}

export default FirstSignUp;
